import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable, ReplaySubject} from 'rxjs';
import {GeneralRequestService} from '../../services/general-request.service';
import {AppMessage} from '../../models/app-message.model';
import {AppAttachment} from '../../models/attachment.model';
import {FetchMessagesResponseItem} from "../../models/payloads/shared/fetch-request.model";

@Injectable({providedIn: "root"})
export class NotificationService {
    notificationMeetingChanged = new ReplaySubject(1);
    private notificationReply: NotificationReply;
    private isNavigateFromMessage = false;
    private notifications: AppMessage[] = [];
    private allNotifications: AppMessage[] = [];
    private notificationsChanged = new BehaviorSubject<AppMessage[]>(
        this.notifications.slice()
    );
    private codes: {
        patientToHcp: number;
        hcpToPatient: number;
        systemToPatient: number;
    };

    constructor(private generalReqService: GeneralRequestService) {
    }

    fetchNotificationsOnInit() {
        this.setCodes();
        this.generalReqService.fetchAllLatestMessages(100).then((items) => {
            if (items) {
                this.allNotifications = this.transformToAppMessages(items);
                this.notifications = this.filterOutThreadTops(
                    this.allNotifications.slice()
                );
                this.notificationsChanged.next(this.notifications.slice());
            }
        });
    }

    setCodes() {
        this.codes = {
            patientToHcp: JSON.parse(
                sessionStorage.getItem("MESSAGE_DIRECTION_PATIENT_HCP")
            ),
            hcpToPatient: JSON.parse(
                sessionStorage.getItem("MESSAGE_DIRECTION_HCP_PATIENT")
            ),
            systemToPatient: JSON.parse(
                sessionStorage.getItem("MESSAGE_DIRECTION_SYSTEM_PATIENT")
            ),
        };
    }

    fetchNewNotifications() {
        this.generalReqService.fetchAllLatestMessages(5).then((items) => {
            if (items && items[0]) {
                if (this.allNotifications.length === 0) {
                    this.allNotifications = this.transformToAppMessages(items);
                    this.notifications = this.filterOutThreadTops(
                        this.allNotifications.slice()
                    );
                    this.notificationsChanged.next(this.notifications.slice());
                } else if (items[0].id !== this.allNotifications[0].messageId) {
                    let newItems = this.transformToAppMessages(items);
                    let indexOfCurrentFirst;
                    for (let i = 0; i < newItems.length; i++) {
                        if (newItems[i].messageId === this.allNotifications[0].messageId) {
                            indexOfCurrentFirst = i;
                            break;
                        }
                    }
                    this.allNotifications = newItems
                        .slice(0, indexOfCurrentFirst)
                        .concat(this.allNotifications);
                    this.notifications = this.filterOutThreadTops(
                        this.allNotifications.slice()
                    );
                    this.notificationsChanged.next(this.notifications.slice());
                }
            }
        });
    }

    fetchMessageThread(messageId: number) {
        return this.generalReqService
            .fetchMessageThread(messageId)
            .then((items) => {
                if (items && items.length !== 0) {
                    return this.transformToAppMessages(items)[0];
                } else return;
            });
    }

    fetchAttachments(id: string): Promise<AppAttachment[]> {
        return this.generalReqService.fetchAttachments(id).then((response) => {
            if (response) {
                let appAttachments: AppAttachment[] = [];
                for (let i = 0; i < response.length; i++) {
                    appAttachments.push(
                        new AppAttachment(
                            response[i].id,
                            response[i].metadata,
                            response[i].name,
                            response[i].messageId
                        )
                    );
                }
                return appAttachments;
            }
            return;
        });
    }

    getNotifications(): Observable<AppMessage[]> {
        return this.notificationsChanged.asObservable();
    }

    setNotificationReply(reply: NotificationReply) {
        this.notificationReply = reply;
    }

    getNotificationReply() {
        return this.notificationReply;
    }

    setNotificationMeeting(roomCode: string, roomPassword: string) {
        const notificationMeeting: any[] = [];
        notificationMeeting.push(roomCode, roomPassword);
        this.notificationMeetingChanged.next(notificationMeeting.slice());
    }

    setIsNavigateFromMessage(isFromMessage: boolean) {
        this.isNavigateFromMessage = isFromMessage;
    }

    getIsNavigateFromMessage() {
        return this.isNavigateFromMessage;
    }

    readNotification(messageId: number) {
        const notification = this.allNotifications.filter(
            (elem) => elem.messageId === messageId
        )[0];
        if (notification && notification.isUnread) {
            const statusCodeRead = JSON.parse(
                sessionStorage.getItem("MESSAGE_STATUS_IN_PROGRESS")
            );
            const statusObj = {
                mode: "SAVE_PARTIAL",
                items: [
                    {
                        type: "message",
                        id: notification.messageId,
                        statusCodeId: statusCodeRead,
                        crossCommunicationStatus: notification.crossCommunicationStatus
                    },
                ],
            };
            this.generalReqService.postRequest(statusObj);
            const sub = this.generalReqService
                .getRequestResponse()
                .subscribe((body) => {
                    // console.log("Response mode: ", body.mode);
                    sub.unsubscribe();
                });
            notification.isUnread = false;
        }
        this.notifications = this.filterOutThreadTops(
            this.allNotifications.slice()
        );
        this.notificationsChanged.next(this.notifications.slice());
    }

    private transformToAppMessages(
        items: FetchMessagesResponseItem[]
    ): AppMessage[] {
        let appMessageArray = [];
        const patientId = JSON.parse(sessionStorage.getItem("sessionContext"))
            .principal.id;
        for (let i = 0; i < items.length; i++) {
            if (
                (items[i].directionCodeId === this.codes.patientToHcp &&
                    items[i].sentByPersonId === patientId) ||
                ([this.codes.hcpToPatient, this.codes.systemToPatient].includes(
                        items[i].directionCodeId
                    ) &&
                    items[i].sentToPersonId === patientId)
            ) {
                let newMessage = this.createAppMessage(items[i]);
                if (items[i].masterMessage && items[i].masterMessage.sentByPerson) {
                    newMessage.masterMessage = this.transformToAppMessages([
                        items[i].masterMessage,
                    ])[0];
                }
                appMessageArray.push(newMessage);
            }
        }
        return appMessageArray;
    }

    private createAppMessage(item: FetchMessagesResponseItem) {
        const statusIsUnread: boolean =
            item.statusCodeId ===
            JSON.parse(sessionStorage.getItem("MESSAGE_STATUS_NEW"));
        const messageSender =
            item.sentByPerson.firstName.toLowerCase() +
            " " +
            item.sentByPerson.lastName.toLowerCase();
        const requireAktCode = +sessionStorage["REQUIRE_TEST_REQUIRE_AKT"];
        const requiresAsthmaTest = item.aktRequirement || item.requiredTestId === requireAktCode ? true : false;
        const requiredTestId = item.requiredTestId;
        const masterMessageId = item.masterMessageId ? item.masterMessageId : null;
        const isDirectionToPatient =
            item.sentToPersonId ===
            JSON.parse(sessionStorage.getItem("sessionContext")).principal.id;
        const attachmentsExist = item.attachmentsExist || !!(item.attachmentList && item.attachmentList.length > 0 && item.attachmentList[0].id);
        const attachmentList = item.attachmentList ? this.createAppAttachmentList(item.attachmentList) : [];
        const threadTop = item.threadTop;
        const isCrossCommunication = item.crossCommunicationStatus;
        const registerId = item.registerId;
        const sentByPersonId = item.sentByPersonId
        const sentToPersonId = item.sentToPersonId
        const messageSendTimeDate = item.messageSendTime ? new Date(item.messageSendTime) : null;
        let newMessage = new AppMessage(
            item.typeCode.code,
            messageSender,
            item.subject,
            new Date(item.insertTime),
            statusIsUnread,
            item.content,
            item.id,
            attachmentsExist,
            requiresAsthmaTest,
            requiredTestId,
            attachmentList,
            masterMessageId,
            null,
            isDirectionToPatient,
            threadTop,
            isCrossCommunication,
            registerId,
            sentByPersonId,
            sentToPersonId,
            messageSendTimeDate
        );
        return newMessage;
    }

    private filterOutThreadTops(messageList: AppMessage[]) {
        let sortedList: AppMessage[] = [];
        for (let i = messageList.length - 1; i >= 0; i--) {
            if (
                messageList.findIndex(
                    (msg) => msg.masterMessageId === messageList[i].messageId
                ) === -1
            ) {
                sortedList.unshift(messageList[i]);
            }
        }
        return sortedList;
    }

    private createAppAttachmentList(list: any[]): AppAttachment[] {
        let newList = [];
        list.forEach(attachment => {
            newList.push(new AppAttachment(attachment.id, attachment.metadata, attachment.name, attachment.messageId));
        });
        return newList;
    }
}

export class NotificationReply {
    message: AppMessage;
    includeAsthmaTest: boolean;

    constructor(message: AppMessage, includeAsthmaTest: boolean) {
        this.message = message;
        this.includeAsthmaTest = includeAsthmaTest;
    }
}
